<template>
  <div v-if="hasDeviceDifferentTimezoneOffset(props.timezone, props.date) && isMounted">
    <Badge
      :text="useT('xyz109', { timezone: `${props.timezone?.replaceAll('_', ' ')} (${timezoneOffsetName})` }, props.lang ? { locale: props.lang } : {})"
      :type="props.type"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'neutral',
  },
  date: {
    type: String,
    default: new Date().toISOString(),
  },
  timezone: {
    type: String,
    required: true,
  },
  lang: {
    type: String,
    default: null,
  },
});

// Refs
const isMounted = useMounted();

// Computed
const timezoneOffsetName = computed(() => useDayjs(props.date).tz(props.timezone).format('z'));
</script>
